// @ts-check
// import key from "keymaster";
import queryString from "query-string";
import Storage from "browserstorage-ttl";
import { notification } from "antd";
// import userflow from "userflow.js";
import { getIntercomSettings } from "../utils/intercom";
import { handleError } from "../utils/api";
import moment from "moment";
// import { AppContext } from "../app-context";
import {
  ProjectsApi,
  SaasOnlyApi,
  UsersApi,
  CompaniesApi,
  SettingsApi,
  TestCasesApi
} from "../services/generated-sdk";
import trackEvent from "../common/utils";
import { storeUserSession } from "../utils/common-ts";
// import { getIntercomSettings } from "../utils/common";
import { LoadFullstory } from "../scripts/LoadFullstory";
import { onLogout, fetchFeatureStatus } from "../utils/data-residency";

const _ = require("lodash");
const getProjectTags = async projectId => {
  try {
    const API = new TestCasesApi();
    const data = await API.getTestCasesTags({ project: projectId });
    return data;
  } catch (e) {
    return [];
  }
};
function setDocumentTitle(title) {
  document.title = title;
}
function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

/*

const delay = timeout => new Promise(resolve => setTimeout(resolve, timeout));

*/
const delay = timeout => new Promise(resolve => setTimeout(resolve, timeout));

const handleFlashMessages = queryParams => {
  const values = queryString.parse(queryParams);
  if (values.msg) {
    let messageType = "info";
    if (values.msgType) {
      messageType = values.msgType.toString();
    }
    // notification[messageType]({
    //   message: "",
    //   description: values.msg,
    // });
  }
};

const getProjectId = url => {
  let projectId = false;
  let urlParts = url.replace("/new", "").split("/");
  if (urlParts[1] == "project") {
    projectId = urlParts[2];
  }
  return projectId;
};

const setAuthGlobals = ({ jwt }) => {
  window["JWT"] = jwt;
  window["API_KEY"] = "49468cb06e4bd5184d2f19b92fc9ca8c17d24aea"; // delete me later once v1 api is expired
};

export default {
  namespace: "app",
  state: {
    projectId: false,
    projectTitle: "",
    users: [],
    userIndex: [],
    onlineUsers: [],
    ready: false,
    breadCrumb: [],
    pageTitle: "",
    loggedIn: false,
    isModal: false,
    loggedInUser: {},
    navExpanded: true,
    projects: [],
    companyUsers: [],
    lastAccessedProjects: [],
    config: {},
    keyboardShortcutModal: false,
    company: {}, // selected company/workspace
    companies: [], // companies that user is part of
    defectsEnabled: false,
    requirementsEnabled: false,
    projectTags: false,
    featureModal: {
      visible: false,
      title: "Upgrade Required",
      content: null,
      feature: null
    },
    ssoInfo: {},
    enabledFeatures: [],
    copilotUser: null,
    projectMembers: [],
    projectUsers: []
  },
  subscriptions: {
    setup({ history, dispatch }) {
      //history.push("/project/1/dfds");

      // one-time run functions

      /*
      console.info("binding AppModel subscription...");
      bindEvent(window, "message", function(e) {
        if (e.data.type) {
          if (e.data.type == "oldAppLinkClick") {
            if (e.data.url.indexOf("javascript") > -1) {
              return;
            }
            console.info("Link called from legacy app ", e.data.url);
            let targetURL = e.data.url;
            targetURL = targetURL.replace("legacy/index.php/", "");
            targetURL = targetURL.replace("new", "");
            targetURL = targetURL.replace("//", "/");
            location.hash = targetURL;
          }
        }
      });*/

      // setup pinger
      const pingServer = function() {
        dispatch({
          type: "pingServer"
        });

        //setTimeout(pingServer, 5000);
      };

      // disabled for now - activate in some later version
      // setTimeout(pingServer,5000);

      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      return history.listen(opts => {
        let pathname = opts.pathname;

        pingServer();

        //setActiveNav();

        // flash message handler
        handleFlashMessages(opts.search);

        dispatch({
          type: "fetchUsers"
        });

        //legacyNavHandler(pathname);
        // set current project id

        // if its not a project page
        if (pathname.indexOf("/project/") == -1) {
          dispatch({
            type: "setProjectDetails",
            payload: {
              projectId: false,
              projectArchived: false,
              projectTitle: "",
              defectsEnabled: false,
              requirementsEnabled: false,
              projectMembers: [],
              projectUsers: [],
              projectTags: []
            }
          });
        } else {
          // this is a project page
          const projectId = getProjectId(pathname);

          dispatch({
            type: "setReady",
            payload: { ready: false }
          });

          // is it same project as last state?
          dispatch({
            type: "fetchProjectInfo",
            payload: { projectId }
          });

          /*
          // fetch project members
          dispatch({
            type: "fetchProjectUsers",
            payload: { projectId }
          });
          */

          dispatch({
            type: "setReady",
            payload: { ready: true }
          });

          // if project memebers index is loaded, fetch project users again as they might be modified
          if (pathname.indexOf("/project_members/") != -1) {
            dispatch({
              type: "fetchProjectUsers",
              payload: { projectId: projectId, hardRefresh: true }
            });
          }
        }
      });
    },

    keyboardWatcher({ dispatch, history }) {
      // key("⌘+up, ctrl+up", () => {
      //   alert("Hey mate, all good here...");
      // });
      // key("t", () => {
      //   alert("Sorry I have a bug, cant work...");
      //   //let history1 = createHistory();
      //   //let history1 = createBrowserHistory()
      //   //history.push("/project/1/keyboardWatcher");
      //   //location.pathname = "/project/1/keyboardWatcher";
      // });
    }
  },
  reducers: {
    setBeyboardShortcutModal(state, { payload }) {
      return {
        ...state,
        keyboardShortcutModal: payload.show
      };
    },
    setNavExpanded(state, { payload }) {
      return {
        ...state,
        navExpanded: payload.status
      };
    },
    setLoggedInUser(state, { payload }) {
      const userPref =
        payload.user && payload.user.preferences
          ? payload.user.preferences
          : {};
      let sortedProjects = [];
      if (typeof userPref == "object") {
        // sortedProjects = _.sortBy(userPref.lastAccessedProjects, ["last_accessed"]);
      }

      //debugger;
      return {
        ...state,
        loggedIn: payload.user ? true : false,
        loggedInUser: payload.user,
        lastAccessedProjects:
          userPref.last_accessed_projects || userPref.lastAccessedProjects || []
      };
    },
    setLoggedInUserToken(state, { payload }) {
      // if(payload.token)
      return {
        ...state,
        loggedInUser: { ...state.loggedInUser, token: payload.token }
      };
    },
    setBreadcrumb(state, { payload }) {
      return {
        ...state,
        breadCrumb: payload.links
      };
    },
    setPageTitle(state, { payload }) {
      // alert("SETTING TITLE " + state.projectId + " TITLE " + payload.title);
      if (state.projectId && payload.removeProject !== true) {
        payload.title
          ? state.projectTitle
            ? setDocumentTitle(state.projectTitle + " :: " + payload.title)
            : setDocumentTitle(payload.title)
          : setDocumentTitle(state.projectTitle);
      } else {
        setDocumentTitle(payload.title);
      }

      return {
        ...state,
        pageTitle: payload.title
      };
    },
    setModalOpen(state, { payload }) {
      return {
        ...state,
        isModal: payload.isModal
      };
    },
    setPageTitleHTML(state, { payload }) {
      return {
        ...state,
        pageTitleHTML: payload.html
      };
    },
    setPlanDetails(state, { payload }) {
      return {
        ...state,
        planDetails: payload
      };
    },
    updateCompanyDetails(state, { payload }) {
      return {
        ...state,
        company: { ...state.company, ...payload }
      };
    },
    setProjectDetails(state, { payload }) {
      const incoming = _.get(payload, "projectId");
      const current = _.get(state, "projectId");

      let projectTags = [];
      if (incoming !== current && incoming) {
        // try {
        //   if (userflow != undefined) {
        //     if (userflow.isIdentified()) {
        //       // User is identified. Add projectId
        //       userflow.updateUser({ projectId: incoming });
        //     }
        //   }
        // } catch (e) {
        //   console.log({ userflow_error: e });
        // }
        if (current) projectTags = payload.projectTags; // need to check why this is here
      }
      projectTags = payload.projectTags;

      return {
        ...state,
        projectId: payload.projectId,
        projectTitle: payload.projectTitle,
        projectArchived: payload.projectArchived || false,
        projectMembers: payload.projectMembers,
        projectSettingsByKey:
          payload.projectSettingsByKey || state.projectSettingsByKey,
        defectsEnabled:
          payload.defectsEnabled != undefined ? payload.defectsEnabled : false,
        requirementsEnabled:
          payload.requirementsEnabled != undefined
            ? payload.requirementsEnabled
            : state.requirementsEnabled ? state.requirementsEnabled : false,
        projectTags,
        projectUsers: payload.projectUsers
      };
    },
    setOnlineUsers(state, { payload }) {
      return {
        ...state,
        onlineUsers: payload.onlineUsers
      };
    },
    setSystemStatus(state, { payload }) {
      return {
        ...state,
        online: payload.status != false
      };
    },
    setUpgradeModal(state, { payload }) {
      return {
        ...state,
        featureModal: payload
      };
    },
    setUsers(state, { payload }) {
      //if (state.projectId != payload.projectId) {

      const _ = require("lodash");
      const userIndex = _.keyBy(payload.users, "id");

      return {
        ...state,
        users: payload.users,
        userIndex: userIndex
      };
    },
    setReady(state, { payload }) {
      return {
        ...state,
        ready: payload.ready
      };
    },
    setProjects(state, { payload }) {
      let projects = payload.projects;
      // payload.projects.forEach((project) => {
      //   projects.push(project.Project);
      // });
      return {
        ...state,
        projects
      };
    },
    setConfig(state, { payload }) {
      return {
        ...state,
        config: payload.config
      };
    },
    setCompany(state, { payload }) {
      const { settings } = payload.company;
      let hasActivePlan = false;
      if (payload.company.plan) {
        // Possible values are incomplete, trialing, active, past_due, incomplete_expired, canceled, or unpaid.

        if (
          payload.company.planStatus === "active" ||
          payload.company.planStatus === "trialing" ||
          payload.company.planStatus === "past_due" ||
          payload.company.planStatus === "incomplete"
        ) {
          if (
            payload.company.trialDetails &&
            payload.company.trialDetails.isActive
          ) {
            try {
              hasActivePlan = moment
                .unix(payload.company.trialDetails.expiration)
                .isAfter(new Date());
            } catch (error) {
              console.log({ setCompany_error: error });
            }
          } else {
            hasActivePlan = true;
          }
        }
      }

      payload.company.hasActivePlan = hasActivePlan;
      if (settings && Array.isArray(settings) && settings.length) {
        const timeZoneSetting = _.find(settings, { name: "timezone" });
        if (timeZoneSetting) {
          // console.log({ "setting timezone to ": timeZoneSetting });
          moment.tz.setDefault(timeZoneSetting.value);
          window["TIMEZONE"] = timeZoneSetting.value;
        }
      }

      const prev = _.lowerCase(_.get(state, "company.plan"));
      const current = _.lowerCase(_.get(payload, "company.plan"));
      if (prev === "free" && current && current !== prev) {
        // Plan upgraded, send userflow event
        // try {
        //   if (
        //     process.env.USERFLOW_TOKEN &&
        //     userflow != undefined &&
        //     !inIframe()
        //   ) {
        //     if (!userflow.isIdentified()) {
        //       userflow.init(process.env.USERFLOW_TOKEN);
        //     }
        //     if (!userflow.isIdentified()) {
        //       userflow.track("plan_upgraded", {
        //         plan: current
        //       });
        //     }
        //   }
        // } catch (e) {
        //   console.log({ userflow_error: e });
        // }
      }

      // debugger;
      return {
        ...state,
        company: payload.company
      };
    },
    setCompanyUsers(state, { payload }) {
      return {
        ...state,
        companyUsers: payload.users
      };
    },
    setCompanies(state, { payload }) {
      return {
        ...state,
        companies: payload.companies
      };
    },
    setDefectEnabled(state, { payload }) {
      return {
        ...state,
        defectsEnabled: payload.isEnabled
      };
    },
    setRequirementEnabled(state, { payload }) {
      return {
        ...state,
        requirementsEnabled: payload.isEnabled
      };
    },
    updateProjectSettings(state, { payload }) {
      return {
        ...state,
        projectSettingsByKey: { ...state.projectSettingsByKey, ...payload }
      };
    },
    setSsoInfo(state, { payload }) {
      return {
        ...state,
        ssoInfo: payload.ssoInfo
      };
    },
    setEnabledFeatures(state, { payload }) {
      return {
        ...state,
        enabledFeatures: payload.enabledFeatures
      };
    },
    setCopilotUser(state, { payload }) {
      return {
        ...state,
        copilotUser: payload.copilotUser
      };
    },
    setProjectMembers(state, { payload }) {
      return {
        ...state,
        projectMembers: payload.members
      };
    },
    setProjectUsers(state, { payload }) {
      return {
        ...state,
        projectUsers: payload.users
      };
    },
    setProjectTags(state, { payload }) {
      return {
        ...state,
        projectTags: payload.tags
      };
    },
    setLoggedInUserNotificationPreferences(state, { payload }) {
      return {
        ...state,
        loggedInUser: {
          ...state.loggedInUser,
          preferences: {
            ...state.loggedInUser.preferenes,
            notification: payload.preferences
          }
        }
      };
    },
    setLastAccessedProjects(state, { payload }) {
      return {
        ...state,
        loggedInUser: {
          ...state.loggedInUser,
          preferences: {
            ...state.loggedInUser.preferences,
            lastAccessedProjects: payload.lastAccessedProjects
          }
        }
      };
    }
  },
  effects: {
    *fetchProjectSettings({ payload }, { call, put, select }) {
      console.info("Fetching project settings...");

      //return;

      const projectId = getProjectId(document.location.pathname);

      if (!projectId) {
        return;
      }
      yield put({
        type: "setReady",
        payload: { ready: false }
      });

      // is it same project as last state?
      yield put({
        type: "fetchProjectInfo",
        payload: { projectId }
      });

      // fetch project members
      yield put({
        type: "fetchProjectUsers",
        payload: { projectId }
      });

      yield put({
        type: "setReady",
        payload: { ready: true }
      });
    },
    *updateUserDetails(
      { payload: { user, storeSession = false, remember = false } },
      { call, put, select }
    ) {
      const api = new UsersApi();
      const getFullUser = async () => {
        try {
          const userFull = await api.getMyUser();
          return userFull;
        } catch (e) {
          console.log({ getUser_error: e });
          // handleError(e.status);
          // console.error("Unable to fetch companies!!!");
        }
      };
      const userFull = yield call(getFullUser);

      yield put({
        type: "setLoggedInUser",
        payload: {
          user: { ...userFull }
        }
      });
    },
    *updatePlanDetails_v2(
      { payload: { company, planDetails } },
      { call, put, select }
    ) {
      /*
      const fetchPlanDetails = async () => {
        try {
          const companyAPI = new CompaniesApi();
          const plan = await companyAPI.getCompanyPlan({ id: company });
          // console.log({ userFull });

          return plan;
        } catch (e) {
          handleError(e.status);
          return [];
          console.error("Unable to fetch companies!!!");
        }
      };
      */
      //const planDetails = yield call(fetchPlanDetails, company);
      yield put({
        type: "setPlanDetails",
        payload: planDetails
      });
    },
    *updatePlanDetails({ payload: { company } }, { call, put, select }) {
      const fetchPlanDetails = async () => {
        try {
          const companyAPI = new CompaniesApi();
          const plan = await companyAPI.getCompanyPlan({ id: company });
          // console.log({ userFull });

          return plan;
        } catch (e) {
          handleError(e.status);
          return [];
          console.error("Unable to fetch companies!!!");
        }
      };
      const planDetails = yield call(fetchPlanDetails, company);
      yield put({
        type: "setPlanDetails",
        payload: planDetails
      });
    },
    *updateUsageDetails({ payload: { company } }, { call, put, select }) {
      const fetchUsageDetails = async () => {
        try {
          const companyAPI = new CompaniesApi();
          const details = await companyAPI.getCompany({ id: company });
          //@todo Throw error maybe if not fetched usage details
          return details ? details.usageDetails : {};
        } catch (e) {
          handleError(e.status);
          return [];
          console.error("Unable to fetch companies!!!");
        }
      };
      const usageDetails = yield call(fetchUsageDetails, company);
      yield put({
        type: "updateCompanyDetails",
        payload: {
          usageDetails
        }
      });
    },
    *afterLogin(
      {
        payload: {
          user,
          init = false,
          storeSession = false,
          remember = false,
          callback = loggedIn => {}
        }
      },
      { call, put, select, all }
    ) {
      if (storeSession) {
        let expiry = 0;
        if (remember) {
          expiry = 14 * 24 * 60; // 14 days in minutes
        }
        storeUserSession(user, expiry);
      }

      window["JWT"] = user.jwt;

      setAuthGlobals({
        jwt: user.jwt
      });

      // fetch user companies
      // console.info("Fetching companies...");
      const getFeatureStatus = async (featureName = null) => {
        try {
          let featureResult = await fetchFeatureStatus(
            featureName ? featureName : "qa_copilot"
          );
          // localStorage.setItem(`feature-${featureName ? featureName : "qa_copilot"}`, featureResult ? "1" : "0");
          return featureResult;
        } catch (e) {
          console.log({ getFeatureStatus_error: e });
        }
        return false;
      };

      const api = new UsersApi();
      const getCompanies = async () => {
        try {
          const userFull = await api.getMyUser();
          return userFull;
        } catch (e) {
          // handleError(e.status);
          console.error("Unable to fetch companies!!!");
        }
      };

      const fetchProjects = async company => {
        try {
          const projectAPI = new ProjectsApi();
          const projects = await projectAPI.getProjects({ company, limit: -1 });
          return projects;
        } catch (e) {
          handleError(e.status);
          return [];
          console.error("Unable to fetch companies!!!");
        }
      };
      const fetchRecentProjects = async (company, userId) => {
        try {
          const projectAPI = new ProjectsApi();
          const projects = await projectAPI.getProjects({
            company,
            limit: 5,
            sort: "activity:desc",
            filter: JSON.stringify({ "team.user": userId })
          });
          return projects;
        } catch (e) {
          // handleError(e.status);
          return [];
        }
      };
      const fetchPaymentProfile = async company => {
        try {
          const api = new SaasOnlyApi();
          const profiles = await api.getPaymentProfiles({ company });
          return _.head(profiles);
        } catch (e) {
          handleError(e.status);
          return null;
          console.error("Unable to fetch companies!!!");
        }
      };
      const fetchSettings = async company => {
        try {
          const settingsAPI = new SettingsApi();
          const settings = await settingsAPI.getSettings({ company });
          //console.log({ settings })
          //debugger;
          return settings;
        } catch (e) {
          handleError(e.status);
          return [];
          console.error("Unable to fetch companies!!!");
        }
      };

      const getUsers = async company => {
        try {
          const users = await api.getUsers({ company });
          return users;
        } catch (e) {
          handleError(e.status);
          console.error("Unable to fetch company users!!!");
          return [];
        }
      };

      const fetchCompanyStats = async company => {
        try {
          const companyAPI = new CompaniesApi();
          const compStats = await companyAPI.getCompany({
            id: company,
            includeStats: true
          });
          return compStats;
        } catch (e) {
          handleError(e.status);
          return {};
          console.error("Unable to fetch companies!!!");
        }
      };

      const fetchPlanDetails = async company => {
        try {
          const companyAPI = new CompaniesApi();
          const plan = await companyAPI.getCompanyPlan({ id: company });
          //console.log({ plan })
          // console.log({ userFull });

          return plan;
        } catch (e) {
          handleError(e.status);
          return [];
          console.error("Unable to fetch companies!!!");
        }
      };

      let companyData = null;
      let users = null;
      let projects = null;
      let settings = null;
      let paymentProfile = null;
      try {
        let userFull = yield call(getCompanies);
        // console.log({ userFull });
        let companyStats = null;
        if (userFull) {
          const { companies } = userFull;
          if (
            !init &&
            !userFull.name &&
            !(companies && companies.length) &&
            !location.pathname.includes("company_setup")
          ) {
            location.pathname = "/company_setup";
          }

          if (
            !(companies && companies.length) &&
            !location.pathname.includes("company_setup")
          ) {
            location.pathname = "/company_setup";
          }

          yield put({
            type: "setCompanies",
            payload: {
              companies: companies
            }
          });
          let recentProjectsData = [];
          if (companies && companies.length == 1) {
            const company = companies[0];
            companyData = companies[0];
            /*
            let featureResult = yield call(getFeatureStatus);
            let bulkAutomateFeatureResult = yield call(
              getFeatureStatus,
              "qa_copilot_bulk_automation"
            );*/
            const [
              featureResult,
              bulkAutomateFeatureResult,
              testDatasetFeatureResult
            ] = yield all([
              call(getFeatureStatus),
              call(getFeatureStatus, "qa_copilot_bulk_automation"),
              call(getFeatureStatus, "test_datasets")
            ]);

            let enabledFeaturesPayload = [];
            if (featureResult) {
              enabledFeaturesPayload.push("qa_copilot");
              companyData.qacFeatureEnabled = true;
            }
            if (bulkAutomateFeatureResult) {
              enabledFeaturesPayload.push("qa_copilot_bulk_automation");
            }
            if (testDatasetFeatureResult) {
              enabledFeaturesPayload.push("test_datasets");
            }
            yield put({
              type: "setEnabledFeatures",
              payload: {
                enabledFeatures: enabledFeaturesPayload
              }
            });

            //users = yield call(getUsers, company["id"]);

            /*
            users = yield call(getUsers, company["id"]);

            projects = yield call(fetchProjects, company["id"]);
            settings = yield call(fetchSettings, company["id"]);
            paymentProfile = yield call(fetchPaymentProfile, company["id"]);
            companyStats = yield call(fetchCompanyStats, company["id"]);*/
            //const planDetails = yield call(fetchPlanDetails, company);
            const [
              users,
              projects,
              settings,
              paymentProfile,
              companyStats,
              planDetails,
              recentProjects
            ] = yield all([
              call(getUsers, company["id"]),
              call(fetchProjects, company["id"]),
              call(fetchSettings, company["id"]),
              call(fetchPaymentProfile, company["id"]),
              call(fetchCompanyStats, company["id"]),
              call(fetchPlanDetails, company["id"]),
              call(fetchRecentProjects, company["id"], userFull["id"])
            ]);
            recentProjectsData = recentProjects;

            // const planDetails = yield call(fetchPlanDetails, company["id"]);
            company.settings = settings;

            if (companyStats.stats && companyStats.stats.length) {
              companyData.stats = JSON.parse(companyStats.stats);
            }
            let copilotUser = null;
            if (users && users.length) {
              copilotUser = _.find(users, { isCopilot: true });
            }
            companyData.planDetails = planDetails;
            companyData.copilotUser = copilotUser;

            yield put({
              type: "updatePlanDetails_v2",
              payload: { company: company["id"], planDetails }
            });

            yield put({
              type: "setCompanyUsers",
              payload: { users }
            });
            yield put({
              type: "setCopilotUser",
              payload: { copilotUser }
            });

            yield put({
              type: "setCompany",
              payload: {
                company: {
                  ...company,
                  paymentProfile,
                  stats: companyStats.stats
                }
              }
            });

            yield put({
              type: "setProjects",
              payload: { projects }
            });
          }

          yield put({
            type: "setLoggedInUser",
            payload: {
              user: {
                ...user.user,
                name: userFull.name,
                rank: userFull.rank,
                avatar: userFull.avatar,
                preferences: {
                  ...userFull.preferences,
                  lastAccessedProjects: recentProjectsData
                },
                aboutMe: userFull.aboutMe,
                createdAt: userFull.createdAt,
                token: _.get(userFull, "token.token"),
                isGuest: _.get(userFull, "isGuest"),
                isCopilot: _.get(userFull, "isCopilot")
              }
            }
          });
          if (callback && typeof callback === "function") {
            callback(userFull);
          }
        } else {
          // yield call(delay, 500);
          if (callback && typeof callback === "function") {
            callback(false);
          }
        }
        yield put({
          type: "fetchProjectSettings"
        });

        yield put({
          type: "fetchUsers"
        });

        // yield put({
        //   type: "fetchSaaSInstance"
        // });

        if (process.env.NODE_ENV == "production") {
          //if (CustomEvent) {
          // const event = new CustomEvent("onUserLogin", {
          //   //detail: { user: userFull }
          // });
          //window.dispatchEvent(event);
          //}
          if (userFull && !userFull.isGuest) {
            let icSetting = getIntercomSettings(userFull, {
              ...companyData,
              ...{ paymentProfile }
            });
            window["intercomSettings"] = icSetting;
            try {
              if (window["Intercom"] != undefined) {
                window["Intercom"]("boot", window["intercomSettings"]);
              }
            } catch (e) {
              console.log({ Intercom_error: e });
            }
          }
          // try {
          //   if (
          //     process.env.USERFLOW_TOKEN &&
          //     userflow != undefined &&
          //     !inIframe()
          //   ) {
          //     userflow.init(process.env.USERFLOW_TOKEN);
          //     userflow
          //       .identify(_.get(userFull, "id"), {
          //         name: _.get(userFull, "name"),
          //         company: _.get(companyData, "name"),
          //         plan: _.get(companyData, "plan"),
          //         rank: _.get(userFull, "rank"),
          //         isSuperAdmin:
          //           _.get(companyData, "owner.id") === _.get(userFull, "id"),
          //         signed_up_at: _.get(userFull, "createdAt")
          //       })
          //       .then(() => console.log("DONE....."))
          //       .catch(e => console.log("USERFLOW ERROR", e));
          //     console.log("Initialized successfully...");
          //   } else {
          //     console.log("User flow token not available!");
          //   }
          // } catch (error) {
          //   console.log({ error });
          // }
          try {
            let canLoadFullstory = true;
            if (userFull && userFull.createdAt) {
              if (moment().diff(moment(userFull.createdAt), "days") > 90) {
                canLoadFullstory = false;
              }
            }
            if (canLoadFullstory) {
              LoadFullstory(() => {
                if (window["FS"] != undefined) {
                  window["FS"].restart();
                  window["FS"].identify(_.get(userFull, "id"), {
                    displayName: _.get(userFull, "name"),
                    email: _.get(userFull, "email"),
                    user_id: _.get(userFull, "id"),
                    reviewsWritten_int: 14
                  });
                }
              });
            }
          } catch (e) {
            console.log({ fullstory_error: e });
          }
        }
        trackEvent("Login", null);
      } catch (error) {
        if (callback && typeof callback === "function") {
          callback(false);
        }
      }
    },
    *checkIfSessionExists({ payload }, { call, put, select }) {
      let user = false;
      try {
        //debugger;
        let raw = Storage.get("TC_USER");
        if (typeof raw == "object") {
          user = raw;
        }

        if (typeof raw == "string") {
          user = yield JSON.parse(raw);
        }
      } catch {
        if (payload.callback) payload.callback(false);
        return;
      }

      if (user) {
        //setAuthGlobals({ apiKey: user.api_key });

        yield put({
          type: "afterLogin",
          payload: {
            user: user,
            ...payload
          }
        });
      } else {
        if (payload.callback) payload.callback(false);
      }
    },

    *setLastAccessedProjects({ payload }, { call, put, select }) {
      const userPrefs = yield select(
        state => state.app.loggedInUser.preferences
      );
      if (typeof userPrefs == "object") {
        // const sortedProjects = _.sortBy(userPrefs.lastAccessedProjects, [
        //   "last_accessed"
        // ]);
      }
    },

    *login(
      { payload: { email, password, remember, callback } },
      { call, put, select }
    ) {
      // console.info("Calling server login..");

      // const realApi = new Configuration({
      //   basePath: "http://localhost/api/v2"
      // });
      const api = new UsersApi();

      const login = async loginPayload => {
        try {
          return await api.login({ userLoginPayload: loginPayload });
        } catch (e) {
          if (e.json) {
            e.json().then(data => {
              if (data.statusCode === 400) {
                if (
                  data.message &&
                  data.message.indexOf("reset password link")
                ) {
                  return notification.error({
                    message: data.message
                  });
                }
                return notification.error({
                  message: "Email or password invalid."
                });
              }
              handleError(data.statusCode, {}, data.message || "");
            });
          } else {
            console.log({ login_error: e });
            notification.warn({
              message:
                e && e.message
                  ? e.message
                  : "Could not process your request at the moment. Please try again."
            });
          }
          if (callback) callback();
        }
      };
      const user = yield call(login, {
        identifier: email,
        password
      });
      // const user = yield call(login, {
      //   identifier: "abhi@testcollab.com",
      //   password: "longpassword"
      // });
      if (user && user.jwt) {
        yield put({
          type: "afterLogin",
          payload: {
            user: user,
            storeSession: true,
            remember: remember,
            callback
          }
        });

        // let expiry = 0;
        // if (remember) {
        //   expiry = 14 * 24 * 60; // 14 days in minutes
        // }
        //storeUserSession(user, expiry);

        // loggedInSuccessMsg(user);
      }
    },

    *logout({ payload }, { call, put, select }) {
      console.info("Logging out user...");
      const forceLogout = _.get(payload, "forceLogout");
      const type = _.get(payload, "type");
      window["jwt"] = "";
      const api = new UsersApi();
      const logout = async () => {
        try {
          return await api.logout();
        } catch (e) {
          if (!forceLogout) {
            if (e.json) {
              e.json().then(data => {
                if (data.statusCode === 400) {
                  return notification.error({
                    message: "Email or password invalid."
                  });
                }
                handleError(data.statusCode, {}, data.message || "");
              });
            } else {
              console.log({ logout_error: e });
              notification.warn({
                message:
                  e && e.message
                    ? e.message
                    : "Could not process your request at the moment. Please try again."
              });
            }
          }
        }
      };
      const logoutResult = yield call(logout);

      // alert(forceLogout + " FORCE " + type);
      /*
      sessionStorage.removeItem("TC_USER");
      localStorage.removeItem("TC_USER");*/
      Storage.remove("TC_USER");
      localStorage.removeItem("TC_USER");
      sessionStorage.removeItem("TC_USER");
      onLogout();
      yield put({
        type: "setLoggedInUser",
        payload: {
          user: {}
        }
      });

      let icSetting = getIntercomSettings(null, null);
      icSetting["company"] = {};
      icSetting["company"]["id"] = 0;
      window["intercomSettings"] = icSetting;

      try {
        if (window["Intercom"] != undefined) {
          window["Intercom"]("update", window["intercomSettings"]);
          window["Intercom"]("boot", window["intercomSettings"]);
        }
      } catch (e) {
        console.log({ Intercom_error: e });
      }

      // if (forceLogout) {
      //   location.pathname = "/login?type=" + type;
      // } else {
      //   location.pathname = "/login";
      // }
      location.pathname = "/login";
      // setTimeout(() => {
      //   debugger;
      //   location.pathname = "/login";
      // }, 500);
    },

    *pingServer({ payload }, { call, put, select }) {
      const loggedIn = yield select(state => state.app.loggedIn);
      if (!loggedIn) {
        return;
      }
      console.info("Pinging server...");
    }
  }
};
